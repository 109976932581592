import { AppRoot } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import {
  RouteComponentProps,
  withRouter,
  Route,
  Switch,
  useLocation
} from 'react-router-dom';

import { StoreState } from '^/store/types';
import UI from './ui';
import Export from './export';

type StateProps = Pick<StoreState, 'currentUser'>;

type RouteProps = RouteComponentProps<{}>;

type Props = RouteProps & StateProps;

const App: React.FunctionComponent<Props> = React.memo(props => {
  const location = useLocation();

  const firstSegment = location.pathname
    .split("/")
    .filter(Boolean)[0]
    ?.toLowerCase()
    .replace(/\s+/g, "-") || "home";

  return (
    <AppRoot>
      <div className={`pageSection ${firstSegment}`}>
        <Switch>
          {props.currentUser && <Route path="/export" component={Export} />}
          <Route path="/" component={UI} />
        </Switch>
      </div>
    </AppRoot>
  );
});

const mapStateToProps = ({ currentUser }: StoreState): StateProps => {
  return {
    currentUser,
  };
};

export default withRouter(connect(mapStateToProps)(App));
